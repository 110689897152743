import Vue from 'vue'
import Vuex from 'vuex'
import axios from '@/axios'
import api from '@/api'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    menu: ''
  },
  mutations: {
    SET_MENU: (state, param) => {
      state.menu = param
    }
  },
  actions: {
    getMenu({ commit }) {
      return new Promise((resolve, reject) => {
        const param = new URLSearchParams()
        param.append('permsCategory', '0')
        // 获取菜单路由
        axios.get(api.getUserPermissionByToken, { data: { permsCategory: '1' }}).then(res => {
          let menu = []
          if (res.result && res.result.length) {
            menu = res.result
          }
          commit('SET_MENU', menu || [])
          resolve(res)
        }).catch((error) => {
          reject(error)
        })
      })
    }
  },
  modules: {
  }
})
